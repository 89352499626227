.navbar {
  transition: top 0.3s;
  border-bottom: 1px solid red;
  border-image: linear-gradient(to right, transparent 1.5%, red 1.5% , red 98% , transparent 98%) 100% 1 !important;
}

.navbar-nav {
  margin-right: 43px;
}

.navbar.hidden {
  top: -100px; /* Adjust this value based on the actual height of your navbar */
}
  
.navbar.visible {
  top: 0;
}

.navbar-brand {
  margin-left: 38px;
  position: relative;
  right: 28px;
}

.navbar-brand , .nav-link{
  font-size: 24px !important;
  font-weight: 400;
  line-height: 29px;
  color: #fff !important;
}

.nav-link:hover , .nav-link.active{
  color: red !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

@media screen and (max-width: 576px) {
  .navbar-brand , .nav-link{
    font-size: 20px !important;
  }
  
}