@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@font-face {
    font-family: 'Dekar';
    src: url('../fonts/Dekar-Regular.eot');
    src: url('../fonts/Dekar-Regular.woff2') format('woff2'),
    url('../fonts/Dekar-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dekar';
    src: url('../fonts/Dekar-Light.eot');
    src: url('../fonts/Dekar-Light.woff2') format('woff2'),
    url('../fonts/Dekar-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

.footer-font {
    font-family: 'Nunito Sans', sans-serif;
    text-decoration: underline white;
}