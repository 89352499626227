.hero-section {
  position: relative;
  background-image: url('../images/Hero.jpg'); /* Replace with the path to your image */
  background-size: cover;
  background-position: center;
  max-width: 100%;
  height: 100vh;
}

.hero-container {
  color: white;
  text-align: left;
  opacity: 1;
  position: absolute;
  top: 60%;
  transform: translate(0%, -55%); 
}

.title {
  font-size: 90px;
  font-weight: 700;
  margin-bottom: 2rem;
  letter-spacing: .4rem;
  color: red;
}

.description {
  font-family: 'Nunito Sans', sans-serif;
  max-width: 600px;
  font-size: 24px;
  line-height: 1.2;
}

.spotlight {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
      circle,
      transparent 10%,
      rgba(0, 0, 0, 0.9) 35%
  );
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 50px;
  }

  .description {
    font-size: 15px;
    max-width: 375px;
  }
}