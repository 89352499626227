.skills-languages-container{ 
  background-image: url('../logo/png_logo_line.svg');
  background-size: 550px;
  background-position: center right 400px;
  background-repeat: no-repeat;
}

.skills h2, .languages h2 {
  margin-bottom: 20px;
}

.language {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.proficiency {
  display: flex;
}

.experience-entry {
  font-family: 'Nunito Sans', sans-serif;
  margin-bottom: 20px;
  color: #fff;
  border: none !important;
  border-bottom: 6px solid #fff !important;
  border-image: linear-gradient(to right, #fff 30%, transparent 30%) 100% 1 !important;
}

.circleSize {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 576px) {
  .circleSize {
    width: 14px;
    height: 14px;
  }
  
}