@import url('./styles/navbar.css');
@import url('./styles/hero.css');
@import url('./styles/about.css');
@import url('./styles/projects.css');

/* general */
.container-margin{
  margin-left: 130px !important;
}
.margin-right{
  margin-right: 130px !important;
}

.xl-text{
  font-size: 50px;
  font-weight: 700;
  letter-spacing: .3rem; 
}

.l-text{
  font-size: 48px;
  font-weight: 700;
}

.m-text{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 35px;
  font-weight: 100;
  line-height: 48px;
}

.sm-text{
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: .2rem;
}

.s-text{
  font-family: 'Nunito Sans', sans-serif;
  max-width: 400px;
  font-size: 20px;
  font-weight: 500;
}

footer{
  max-width: 90%;
}

h3, h4, h5{
  font-weight: lighter !important;
}

@media screen and (max-width: 576px) {
  .xl-text{
    font-size: 30px; 
  }
  
  .l-text{
    font-size: 48px;
  }
  
  .m-text{
    font-size: 24px;
  }
  
  .sm-text{
    font-size: 28px;  
  }
  
  .s-text{
    max-width: 360px;
    font-size: 14px;
  }
}