.scroll-container {
  background: #aaa;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 625px;
  -webkit-box-shadow: 7px 8px 15px 0px rgba(0,0,0,0.76); 
  box-shadow: 7px 8px 15px 0px rgba(0,0,0,0.76);
  scroll-snap-type: y mandatory;
}

/* width */
.scroll-container::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-container::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 2, 2);
}


.scroll-image {
  width: 100%;
  height: inherit;
  scroll-snap-align: start;
}

@media screen and (max-width: 576px) {
  .scroll-container {
    width: 75%;
    max-height: 370px;
  }
}