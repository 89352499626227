.projects-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;  
}

.projects-main-container.logo {
  background-image: url('../images/jpglogo.jpg');
  background-size: 550px;
  background-position: bottom 100px right 200px;
  background-repeat: no-repeat;
}

.projects-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}

.book-margin {
  margin-left:15rem;
}

.projects-text-container {
  display: flex;
  flex-direction: column;
}

.divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.line {
  border: none;
  height: 4px;
  background-color: red;
  opacity: 1 !important;
}

.number {
  color: #000;
  font-size: 100px;
  font-weight: 700;
  line-height: 242px;
  letter-spacing: 0em;
  position: relative;
  z-index: 1;
  padding-left: 160px;
  padding-right: 160px;
}

.small-divider {
  border: none;
  height: 4px;
  background-color: red;
  max-width: 26%;
  opacity: 1 !important;
}


@media screen and (max-width: 576px) {
  .number {
    font-size: 50px;
    line-height: 121px;
    padding-left: 80px;
    padding-right: 80px;
  }
  
}